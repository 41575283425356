import React from 'react'
import styled from 'styled-components'
import { Card } from '@pancakeswap/uikit'

export const BodyWrapper = styled(Card)`
  border-radius: 16px;
  max-width: 436px;
  width: 100%;
  // border: 1px solid #293232;
  border: 1px solid #1E312F;
  padding: 14px;
  z-index: 1;

  // ${({ theme }) => theme.mediaQueries.md} {
  //   padding: 0px !important;
  // }
  
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
